<template>
  <div>
    <c-table
      ref="cycleTable"
      title="검진주기 목록"
      tableId="userTable"
      :columns="grid.columns"
      :merge="grid.merge"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable && grid.data.length > 0"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="grid.data"
            mappingType="POST"
            label="저장"
            icon="save"
            @beforeAction="saveCycle"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'check-up-cycle',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: '',
        userId: '',
        checkupRecentPeriod: [],
        checkupSchedulePeriod: [],
      },
      grid: {
        columns: [
          {
            name: 'typeName',
            field: 'typeName',
            label: '검진종류',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'cycleCd',
            field: 'cycleCd',
            label: '검진주기',
            align: 'center',
            style: 'width:120px',
            type: 'select',
            codeGroupCd: 'CHECK_UP_CYCLE_CD',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            type: 'textarea',
            style: 'width: 200px',
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
      saveable: false,
      editable: true,
      isSave: false,
      saveUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      cycleCdItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      // api scope
      this.listUrl = selectConfig.hea.checkup.cycle.list.url;
      this.saveUrl = transactionConfig.hea.checkup.cycle.save.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveCycle() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
